import React from 'react';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import { useSaveDatabaseMutation } from '../../redux/apis/userApi';
import { Language } from './language/language';
import s from './main.module.scss';
import { Pages } from './pages/pages';
import { AddPost } from './posts/addPost/addPost';
import { Posts } from './posts/posts';
import { Terms } from './terms/terms';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings_icon.svg';
import { Settings } from './settings/settings';
import { Users } from './users/users';
import { Toast } from '../../utils/hocs/toast';
import { confirm } from '../../utils/hocs/confirm';
import { Permissions } from '../../utils/hocs/permissions';
import Swal from 'sweetalert2';

export const Main = ()=>{

    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const userData = JSON.parse(localStorage.getItem('userData'));

    const [saveDatabase] = useSaveDatabaseMutation();

    async function logOut(){
        confirm("Вийти з акаунту?").then((res)=>{
            if(res.isConfirmed){
                localStorage.removeItem('token');
                localStorage.removeItem('userData');
                navigate("/auth/");
            }
        })
    }

    async function saveDB(){
        confirm("Оновити сайт?").then((res)=>{
            if(res.isConfirmed){
                saveDatabase().then(res => {
                    if(res.error?.data.message === 1000){
                        Swal.fire({
                            icon: 'error',
                            title: 'На жаль, у вас немає необхідних прав',
                            confirmButtonText: 'Закрити',
                            customClass: {
                                actions: 'modal',
                                cancelButton: 'order-1 right-gap',
                                confirmButton: 'order-2',
                                denyButton: 'order-3',
                            }
                        })
                    }
                    else{
                        Toast.fire({icon: 'success', title: `Сайт упішно оновлено. Поточна версія бази данних - ${res.data}`});
                    }
                })
            }
        });
    }

    if(!token) return <h1>Loading...</h1>;
    else return(
        <div className={s.main}>
            <section className={s.left_menu}>
                <div className={s.menu}>
                    <div className={s.menu_item}>
                        <SettingsIcon/>
                        <Link to="/">Сторінки сайту</Link>
                    </div>
                    <div className={s.menu_item}>
                        <SettingsIcon/>
                        <Link to="posts/">Пости</Link>
                    </div>
                    <div className={s.menu_item}>
                        <SettingsIcon/>
                        <Link to="/language/">Мови</Link>
                    </div>
                    <div className={s.menu_item}>
                        <SettingsIcon/>
                        <Link to="/terms/">Терміни</Link>
                    </div>
                    {userData.id > 1? null : (
                        <div className={s.menu_item}>
                            <SettingsIcon/>
                            <Link to="users/">Юзери</Link>
                        </div>
                    )}
                    {/* <div className={s.menu_item}>
                        <SettingsIcon/>
                        <Link to="/settings/">Налаштування</Link>
                    </div> */}
                </div>
                <div className={s.bottom_menu}>
                    <div className={s.user_menu}>
                        <p>Привіт, <span>{userData.info.name}</span></p>
                        <p>{userData.email}</p>
                    </div>
                    <div className={s.menu_item}>
                        <p onClick={()=>saveDB()}>Оновити сайт</p>
                    </div>
                    <div className={s.menu_item}>
                        <p onClick={logOut}>Вийти</p>
                    </div>
                </div>
            </section>
            <section className={s.content}>
                <Routes>
                    <Route path="*" element={<Permissions page="pages" type="view"><Pages/></Permissions>}/>
                    <Route path="/addpost/*" element={<Permissions page="posts" type="my"><AddPost/></Permissions>}/>
                    <Route path="/posts/" element={<Permissions page="posts" type="view"><Posts/></Permissions>}/>
                    <Route path="/users/" element={<Users/>}/>
                    <Route path="/terms/" element={<Permissions page="terms" type="view"><Terms/></Permissions>}/>
                    <Route path="/language/" element={<Permissions page="langs" type="view"><Language/></Permissions>}/>
                    <Route path="/settings/" element={<Settings/>}/>
                </Routes>
            </section>
        </div>
    )
}
import { Toast } from './toast';
const Swal = require('sweetalert2');

export function confirm(text){
  return Swal.fire({
        title: text,
        showCancelButton: true,
        confirmButtonText: 'Прийняти',
        cancelButtonText: 'Відхилити',
        customClass: {
          actions: 'modal',
          cancelButton: 'order-1 right-gap',
          confirmButton: 'order-2',
          denyButton: 'order-3',
        }
    });
    
}
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const api = process.env.REACT_APP_API_ADDRESS;

export const blocksApi = createApi({
    reducerPath: 'blocksApi',
    tagTypes: ['Blocks'],
    baseQuery: fetchBaseQuery({
      baseUrl: api,
      prepareHeaders: (headers) => {
        const token = localStorage.getItem("token");
        if(token) headers.set("token", token);
        return headers;
      }
    }),
    endpoints: (builder) => ({

      getBlocks: builder.query({
        query: () => 
`/api/blocks/get`,
        providesTags: (result) =>
        result.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'Blocks', id })),
              { type: 'Blocks', id: 'LIST' },
            ]
          : [{ type: 'Blocks', id: 'LIST' }],
      }),


      addBlock: builder.mutation({
        query: (data) => ({
          url: '/api/blocks/set',
          method: 'POST',
          body: data,
        }),
        invalidatesTags: [{ type: 'Blocks', id: 'LIST' }],
      }),

      addBlockNew: builder.mutation({
        query: (data) => ({
          url: '/api/blocks/set/new/',
          method: 'POST',
          // body: {type: data.type, section_id: data.section_id, link: data.link, terms: data.terms, file: data.file, form_data: data.form_data},
          body: data,
        }),
        invalidatesTags: [{ type: 'Blocks', id: 'LIST' }],
      }),


      updateBlock: builder.mutation({
        query: (data) => ({
          url: '/api/blocks/update',
          method: 'PUT',
          body: data,
        }),
        invalidatesTags: [{ type: 'Blocks', id: 'LIST' }],
      }),

      updateBlockNew: builder.mutation({
        query: (data) => ({
          url: '/api/blocks/update/new',
          method: 'PUT',
          body: data,
        }),
        invalidatesTags: [{ type: 'Blocks', id: 'LIST' }],
      }),


      deleteBlock: builder.mutation({
        query: (id) => ({
          url: '/api/blocks/delete',
          method: 'DELETE',
          body: {id: id},
        }),
        invalidatesTags: [{ type: 'Blocks', id: 'LIST' }],
      }),


      getBlocksTypes: builder.query({
        query: () => `/api/blocks/types/get`})
      })
})

export const {
    useGetBlocksQuery,
    useAddBlockMutation,
    useUpdateBlockMutation,
    useDeleteBlockMutation,
    useGetBlocksTypesQuery,
    useAddBlockNewMutation,
    useUpdateBlockNewMutation
} = blocksApi;
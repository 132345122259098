import { Route, Routes } from "react-router-dom";
import { Auth } from "./pages/user/auth/auth";
import { Main } from "./pages/main/main";
import { Registration } from "./pages/user/registration/registration";
import { ConfirmRegistration } from "./pages/user/confirmRegistration/confirmRegistration";
import './index.scss';

function App() {
  
  return (
    <div className="App">
      <Routes>
        {/* <Route path="/" element={<Main/>}/> */}
        <Route path="*" element={<Main/>}/>
        <Route path="/auth/" element={<Auth/>}/>
        {/* <Route path="/registration/" element={<Auth/>}/>
        <Route path="/registration/confirm" element={<Auth/>}/> */}
      </Routes>
    </div>
  );
}

export default App;

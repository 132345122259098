import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import s from './sectionModal.module.scss';
import { sectionApi, useAddSectionMutation, useUpdateSectionMutation } from '../../../../redux/apis/sectionApi';
import { useDispatch } from 'react-redux';
import { pagesApi } from '../../../../redux/apis/pagesApi';
import { Toast } from '../../../../utils/hocs/toast';
import Swal from 'sweetalert2';

export const SectionModal = ({close, el, pageState})=>{
   
    let modal_errors;
    const dispatch = useDispatch();
    const initialValues = {id: el? el.id : '', name: el? el.name : '', content: el? el.content : ''};
    const validationSchema = yup.object().shape({
        name: yup.string().required("Обов'язкове поле").min(2, 'Нимимум 2 символа'),
    });

    const [updateSection] = useUpdateSectionMutation();
    const [addSection] = useAddSectionMutation();

    
    
    return(
        <div className="modal_wrapper">
            <div className="modal">
                <h2>{el? "Редагувати секцію" : "Додати секцію"}</h2>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={async (values, {setSubmitting, resetForm})=> {
                        setSubmitting(false)
                        if(el) {
                            const res = await updateSection(values);
                            if(res.error?.data.message === 1000){
                                console.log(res);
                                Swal.fire({
                                    icon: 'error',
                                    title: 'На жаль, у вас немає необхідних прав',
                                    confirmButtonText: 'Закрити',
                                    customClass: {
                                      actions: 'modal',
                                      cancelButton: 'order-1 right-gap',
                                      confirmButton: 'order-2',
                                      denyButton: 'order-3',
                                    }
                                })
                                close(false);
                            }
                            else Toast.fire({icon: 'success', title: 'Успіх'});
                        }
                        else {
                            const res = await addSection({values, pageState});
                            if(res.error?.data.message === 1000){
                                console.log(res);
                                Swal.fire({
                                    icon: 'error',
                                    title: 'На жаль, у вас немає необхідних прав',
                                    confirmButtonText: 'Закрити',
                                    customClass: {
                                      actions: 'modal',
                                      cancelButton: 'order-1 right-gap',
                                      confirmButton: 'order-2',
                                      denyButton: 'order-3',
                                    }
                                })
                                close(false);
                            }
                            else if(res.error?.data.message === 34){
                                Toast.fire({icon: 'error', title: `Имя ${res.error.data.data.name} уже занято`});
                            }
                            else{
                                Toast.fire({icon: 'success', title: 'Успіх'});
                                dispatch(pagesApi.util.resetApiState(['Pages']))
                                dispatch(sectionApi.util.resetApiState(['Section']))
                            }
                            close(false);
                        }
                        close(false)
                    }}
                    >
                    {({errors, touched}) => (
                        <Form className="modal_form">
                        
                        <div className="form_row">
                            <label>
                                Назва секції
                            </label>
                            <Field name="name" type="text"/>
                            <span className="error_text">{touched.name? errors.name : null}</span>
                        </div>

                        <button className='button' type="submit">Відправити</button>
                        <button className='button' type="submit" onClick={()=>close(false)}>Скасувати</button>
                        <span className="error_text">{modal_errors}</span>
                    </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}
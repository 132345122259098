import React from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { BlocksModal } from '../../../pages/main/pages/blocksModal/blocksModal';
import s from './blockModal.module.scss';
import { key } from '../../../utils/getKey';

export const BlockModal = ({close, lang_data, success})=>{

    const img_wrapper = useRef();
    const error_text = useRef();

    const [mode, setMode] = useState('left');
    const [block, setBlock] = useState({image: false, text: []});
    const [langState, setLangState] = useState(lang_data[0].id);
    const [termsModal, setTermsModal] = useState(false);

    
    //### FUNCTIONS

    function changeMode(el){
        setMode(el.value);
    }

    function previewImage(file){
        
        const reader = new FileReader();
        reader.onload = function(e) {
        img_wrapper.current.style.cssText= `background: url(${e.target.result}) no-repeat center; background-size: cover; float: ${mode}; margin: 0 ${mode === 'left'? '10px':'0'} 0 ${mode === 'right'? '10px': '0'}`;
        };
        reader.readAsDataURL(file);
        
    }

    function accept(result){
        setBlock(prev => {
            if(result.text){
                const  newState = {};
                newState.image = prev.image;
                newState.text = JSON.parse(JSON.stringify(prev.text));
                result.text.forEach(el => newState.text.push(el));
                return newState;
            }
            if(result.image){
                const newState = JSON.parse(JSON.stringify(prev));
                newState.image = result.image;
                previewImage(result.image.get('file'));
                return newState;
            }
        })
    }

    function addBlock(){
        if(!block.image || !block.text.length){
            error_text.current.innerHTML = "Мінімум 1 зображення та 1 текст";
            return;
        }
        success({id: key() ,data: block, mode: mode});
        close(false)
    }

    return(
        <div className={s.modal_wrapper}>
            <div className={s.modal}>
            <div className={s.lang_block}>
                            {
                            lang_data.map(el => {
                                return (
                                    <div key={el.id} className={s.lang_item} onClick={()=>setLangState(el.id)}>
                                        <div className={s.lang}>{el.key}</div>
                                    </div>
                                )
                            })
                            }
                        </div>
                <h2>Додати блок</h2>
                <div className={s.block_row}>
                    <select name="position" onClick={(ev)=>changeMode(ev.target)}>
                        <option value="left" selected>Картинка сліва</option>
                        <option value="right">Картинка справа</option>
                    </select>
                    <div className={s.button_text} onClick={()=>setTermsModal({type: "image"})}>Додати зображення</div>
                    <div className={s.button_text} onClick={()=>setTermsModal({type: "text"})}>Додати текст</div>
                </div>
                <div className={s.content}>
                    <div className={s.img_wrapper} style={{float: mode, marginRight: mode === 'left'? 15:10, marginLeft: mode === 'right'? 25: null}} ref={img_wrapper}></div>
                    {
                        block.text.map(text => {
                            return text.lang_id === langState? <p className={s.text_block}>{text.value}</p> : false}
                        )
                    }
                </div>
                <p className={s.error_text} ref={error_text}></p>
                <footer>
                    <div className={`${s.button} button`} onClick={()=> addBlock()}>Додати</div>
                    <div className={`${s.button} button`} onClick={()=> close(false)}>Скасувати</div>
                </footer>
            </div>
            {termsModal?<BlocksModal close={setTermsModal} lang_data={lang_data} type={termsModal.type} accept={accept}/>:null}
        </div>
    )
}
import React from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { BlockModal } from '../../../../components/posts/blockModal/blockModal';
import { useGetLangsQuery } from '../../../../redux/apis/langApi';
import { mediaApi } from '../../../../redux/apis/mediaApi';
import { postsApi, useAddPostContentMutation, useAddPostMediaMutation, useAddPostMutation, useAddPostTermsMutation, useGetGroupsTemplateQuery, useGetPostsGroupsQuery } from '../../../../redux/apis/postsApi';
import { termsApi } from '../../../../redux/apis/termsApi';
import { setIsLoading } from '../../../../redux/slices/mainSlice';
import { key } from '../../../../utils/getKey';
import { Toast } from '../../../../utils/hocs/toast';
import { BlocksModal } from '../../pages/blocksModal/blocksModal';
import s from './addPost.module.scss';

export const AddPost = ()=>{

    const urlParams = new URLSearchParams(window.location.search);
    const url_post_group = urlParams.get('group');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const img_wrapper = useRef();
    const error_text = useRef();

    const [groupState, setGroupState] = useState(false);
    const [blockModal , setBlocksModal] = useState(false);
    const [termsModal, setTermsModal] = useState(false);
    const [bodyState, setBodyState] = useState([]);
    const [langState, setLangState] = useState();
    const [templateValues, setTemplateValues] = useState(false);

    const [addPost] = useAddPostMutation();
    const [addPostContent] = useAddPostContentMutation();
    const [addPostTerms] = useAddPostTermsMutation();
    const [addPostMedia] = useAddPostMediaMutation();
    
    const post_groups_query = useGetPostsGroupsQuery({id: url_post_group});
    const post_template_query = useGetGroupsTemplateQuery({id: groupState});
    const lang_query = useGetLangsQuery({});

    if(post_groups_query.isFetching || post_template_query.isFetching || lang_query.isFetching) return <h1>Loading...</h1>

    if(post_groups_query.isFetching || post_template_query.isError || lang_query.isError) console.log(post_groups_query.isError, post_template_query.isError, lang_query.isError);

    if(!groupState){
        setGroupState(post_groups_query.data.data[0].templates);
        return;
    }

    if(!templateValues){
        const newState = {terms: {}};
        post_template_query.data.data.forEach(tm => {
            if(tm.type === 'text' || tm.type === 'link') lang_query.data.data.forEach(ln => newState.terms[`${tm.name}-${ln.id}`] = '')
            else newState[tm.name] = ''
        });
        setTemplateValues(newState)
        return;
    }

    const post_group = post_groups_query.data.data;
    const post_template = post_template_query.data.data;
    const lang = lang_query.data.data;

    if(!langState){
        setLangState(lang[0].id)
    }


    // ### FUNCTIONS

    function closeModal(){
        setBlocksModal(false);
        setTermsModal(false);
    }

    function success(result){
        let error_terms = false;
        console.log(bodyState);
        // check terms
        if(result.text){
            if(bodyState.length){
                for(let i in bodyState){
                    const res = bodyState[i].data.text?.find(tm => tm.pseudo === result.text[0].pseudo);
                    if(res){
                        Toast.fire({icon: 'success', title: `Псевдонім ${res.pseudo} вже зайнятий. Будь ласка, використовуйте унікальні pseudo для тексту.`});
                        error_terms = true;
                        break;
                    }
                }
            }
            for(let i in templateValues.terms){
                if(templateValues.terms[i].pseudo === result.text[0].pseudo){
                    Toast.fire({icon: 'success', title: `Псевдонім ${templateValues.terms[i].pseudo} вже зайнятий. Будь ласка, використовуйте унікальні pseudo для тексту.`});
                    error_terms = true;
                    break;
                }
            }
        }
        if(error_terms) return;

        if(result.tm_name){
            setTemplateValues(prev=>{
                const newState = {...prev};
                if(result.image) newState[result.tm_name] = result.image;
                if(result.text){
                    result.text.forEach(el => newState.terms[`${result.tm_name}-${el.lang_id}`] = el);
                }
                return newState;
            })
        }
        if(result.mode){
            setBodyState(prev => {
                if(prev.length) result.local_id = prev[prev.length - 1].local_id + 1;
                else result.local_id = 1;
                const newState = [...prev, result];
                return newState;
            })
        }
        if(result.post_body){
            let mode;
            if(result.text) mode = 'text';
            if(result.image) mode = 'image';
            const newBody = {data: result, mode: mode}
            
            setBodyState(prev => {
                if(prev.length) newBody.local_id = prev[prev.length - 1].local_id + 1;
                else newBody.local_id = 1;
                const newState = [...prev, newBody];
                return newState;
            })
        }
    }
    

    async function sendPost(){
        dispatch(setIsLoading(true));
        
        // validation
        let error = false;

        for(let el in templateValues){
            if(!templateValues[el]) error = 'Заповніть усі поля';
            if(el === 'terms'){
                for(let ts in templateValues[el]){
                    if(templateValues[el][ts].length < 2) error = 'Заповніть усі поля';
                }
            }
        }
        if(!bodyState.length) error = 'Тіло посту не може бути порожнім.';

        if(error){
            error_text.current.innerHTML = error;
            return;
        } else error_text.current.innerHTML = '';

        
        // send templates terms and media

        let result_terms = [];
        for(let ts in templateValues.terms){
            const terms = templateValues.terms[ts];
            result_terms.push(await addPostTerms({value: terms.value, pseudo: terms.pseudo, group: terms.group, lang_id: terms.lang_id}));
        }
        let terms_error = result_terms.filter(el => el.data?.message !== 17 || el.error?.data.message === 1000);
        if(terms_error.length){
            Swal.fire({
                title: 'На жаль, у вас немає необхідних прав ',
                confirmButtonText: 'Закрити',
                customClass: {
                  actions: 'modal',
                  cancelButton: 'order-1 right-gap',
                  confirmButton: 'order-2',
                  denyButton: 'order-3',
                }
            });
            dispatch(setIsLoading(false));
            return;
        }

        const media_result = await addPostMedia(templateValues.news_title_image);
        if(media_result.data.message !== 28) error_text.current.innerHTML = "Помилка запису media. Будь ласка, зверніться до адміністратора сервісу";

        // send Post
        const template_post = post_template.map(tm => {
            let template = '';
            if(tm.type === 'text') template = templateValues.terms[`${tm.name}-${lang[0].id}`].pseudo;
            if(tm.type === 'file') template = media_result.data.data.id;
            
            return {tm_id: tm.id, tm_name: tm.name, tm_type: tm.type, value: template, tm_title: tm.title}
        })
        
        const post_data = {group: post_group[0].id, template: template_post}
        const post_result = await addPost(post_data);

        if(post_result.data.message !== 49) {
            dispatch(setIsLoading(false));
            return;
        }

        //send body terms and media
        let result_block = [];
        const content_data = [];
        bodyState.forEach(el => {
            if(el.id){
                const temp = {type: 'block', value: '', post_id: post_result.data.data[0].id, style: el.mode, content: {text: [], media: ''}, local_id: el.local_id}
                
                el.data.text?.forEach(async text => {
                    const res = (addPostTerms({value: text.value, pseudo: text.pseudo, group: text.group, lang_id: text.lang_id}));
                    result_block.push(res);
                    if(!temp.content.text.includes(text.pseudo)) temp.content.text.push(text.pseudo);
                })

                const img_res = addPostMedia(el.data.image);
                result_block.push(img_res);
                img_res.then(res => {
                    temp.content.media = res.data.data.id;
                    content_data.push(temp);
                })
                    

            }
            else{
                const temp = {type: '', value: '', post_id: post_result.data.data[0].id, style: '', content: {}, local_id: el.local_id}
                if(el.mode === 'text'){
                    el.data.text?.forEach(async el => {
                        const res = addPostTerms({value: el.value, pseudo: el.pseudo, group: el.group, lang_id: el.lang_id});
                        result_block.push(res);
                        res.then(res => {
                            if(!temp.value.length){
                                temp.value = el.pseudo;
                                temp.type = 'text'
                                content_data.push(temp);
                            }
                        })
                    })
                }
                if(el.mode === 'image'){
                    const res = addPostMedia(el.data.image);
                    result_block.push(res);
                    res.then(res => {
                        temp.value = res.data.data.id;
                        temp.type = 'image';
                        content_data.push(temp);
                    })
                }
            }
        })
        
        // send Post Contents
        Promise.all(result_block).then(() => {
            const result = content_data.map(async el => {
            return await addPostContent(el);
            });
            
            result[result.length -1].then((res) => {
                dispatch(termsApi.util.resetApiState());
                dispatch(mediaApi.util.resetApiState());
                dispatch(postsApi.util.resetApiState());
                dispatch(setIsLoading(false));
                if(res.data.message === 55) {
                    Toast.fire({icon: 'success', title: 'Успіх'});
                    navigate('/posts/');
                }
                else Toast.fire({icon: 'error', title: 'Помилка'});
            })
        })
    }
   

    function changeTmValue(el){
        setTemplateValues(prev => {
            const newState = {...prev};
            newState[el.name] = el.value;
            return newState;
        })
    }


    // ### VIEWS
    
    const template_view = post_template.map(tm => {
        if(tm.type === "text"){
            return(
                <div key={key()} className={s.text_wrapper}>
                    <div className={s.tm_row} >
                        <div className={s.title}>{`${tm.title}`}</div>
                        <div className={s.content}>
                            <div className={`${s.button} button`} onClick={() => setTermsModal({type: 'text', tm_name: tm.name})} >{`Додати ${tm.title}`}</div>
                            <div className={s.lang_block}>
                                {
                                lang.map(el => {
                                    return (
                                        <div key={el.id} className={s.lang_item} onClick={()=>setLangState(el.id)}>
                                            <div className={s.lang}>{el.key}</div>
                                        </div>
                                    )
                                })
                                }
                            </div>
                            <p name={`${tm.name}-${langState}`} onChange={(ev) => changeTmValue(ev.target)} style={{display: templateValues.terms[`${tm.name}-${langState}`]? 'block' : 'none'}}>{templateValues.terms[`${tm.name}-${langState}`]?.value}</p>
                        </div>    
                    </div>
                </div>
            )
        }
        if(tm.type === "file"){
            if(tm.name === "news_title_image"){
                return(
                    <div key={tm.id} className={s.tm_row}>
                        <div className={s.title}>{tm.title}</div>
                        <div className={s.content}>
                            <div className={`${s.button} button`} onClick={() => setTermsModal({type: 'image', tm_name: tm.name})}>Додати файл</div>
                            {templateValues[tm.name]? <div className={s.img_wrapper} ref={img_wrapper} style={{background: `url(${URL.createObjectURL(templateValues[tm.name].get('file'))}) no-repeat center/cover`}}></div>: null}
                        </div>
                    </div>
                )
            }
        }
    });
    
    const body_view = bodyState?.map(el => {
        if(el.mode === 'left' || el.mode === 'right'){
            return(
                <div className={s.block_wrapper}>
                    <img src={URL.createObjectURL(el.data.image.get('file'))} alt="image_preview" style={{float: `${el.mode}`, margin: `0 ${el.mode === 'left'? '10px':'0'} 0 ${el.mode === 'right'? '10px': '0'}`}}/>
                    {el.data.text.map(text => text.lang_id === langState? <p>{text.value}</p>: '')}
                </div>
            )
        }

        if(el.mode === 'text') return el.data.text.map(text => text.lang_id === langState? <p>{text.value}</p>: '');

        if(el.mode === 'image') return <img src={URL.createObjectURL(el.data.image.get('file'))} alt="img_preview" style={{width: '70%', margin: '0 auto'}}/>
    })

    return(
        <div className={s.post}>
            <h3>{`Додати пост у розділ ${post_group[0].name}`}</h3>
            {template_view}
            <div className={s.tm_row}>
                <div className={`${s.title}`}>
                    <div className={s.add_button} onClick={()=>setBlocksModal(true)}>Додати блок</div>
                    <div className={s.add_button} onClick={() => setTermsModal({type: 'text', post_body: "post", tm_name: false})}>Додати текст</div>
                    <div className={s.add_button} onClick={() => setTermsModal({type: 'image', post_body: "post", tm_name: false})}>Додати зображення</div>
                    <span className={s.position}>Тело поста</span>
                </div>
                <div className={s.lang_block_body}>
                    {
                    lang.map(el => {
                        return (
                            <div key={el.id} className={s.lang_item} onClick={()=>setLangState(el.id)}>
                                <div className={s.lang}>{el.key}</div>
                            </div>
                        )
                    })
                    }
                </div>
                <div className={s.body}>
                    {body_view}
                </div>
            </div>
            <div className={s.error_text} ref={error_text}></div>
            <footer>
                    <div className={`${s.add_button} button`} onClick={sendPost}>Додати пост</div>
                    <div className={`${s.add_button} button`}><Link to="/posts/">Скасувати</Link></div>
            </footer>
            {blockModal? <BlockModal close={closeModal} lang_data={lang} success={success}/> : null}
            {termsModal? <BlocksModal close={closeModal} lang_data={lang} type={termsModal.type} tm_name={termsModal.tm_name} post_body={termsModal.post_body} accept={success}/> : null}
        </div>
    )
}
import React from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { Preloader } from '../../../components/preloader/preloader';
import { langApi, useActivateLangMutation, useDeleteLanguageMutation, useGetLangsQuery, useSetLanguageMutation } from '../../../redux/apis/langApi';
import { Toast } from '../../../utils/hocs/toast';
import s from './language.module.scss';

export const Language = ()=>{

    const dispatch = useDispatch();
    const lang_input = useRef();
    const error_text = useRef();

    const [termsState, setTermsState] = useState(false);

    const lang_query = useGetLangsQuery({});
    const [activateLang] = useActivateLangMutation();
    const [setLanguage] = useSetLanguageMutation();
    const [deleteLanguage] = useDeleteLanguageMutation();

    if(lang_query.isFetching) return <Preloader/>
    if(!lang_query.data.data) return;

    const lang = lang_query.data.data;
    
    async function activLang(id){
        error_text.current.innerHTML = "";
        setTermsState(false);

        Swal.fire({
            title: 'Підтвердіть зміни',
            showCancelButton: true,
            confirmButtonText: 'Підтверджую',
            cancelButtonText: 'Відхилити',
            customClass: {
              actions: 'modal',
              cancelButton: 'order-1 right-gap',
              confirmButton: 'order-2',
              denyButton: 'order-3',
            }
          }).then(async (result) => {
            if(result.isConfirmed){
                const result = await activateLang(id);
                if(result.data?.message === 22){
                    dispatch(langApi.util.resetApiState());
                    Toast.fire({icon: 'success', title: 'Успіх'});
                }
                if(result.data?.message === 21){
                    error_text.current.innerHTML = "Будь ласка, заповніть усі текстові поля для цієї мови. ID цих полей:";
                    const terms_view = result.data.data.map(el => {
                        return <span key={el.id}>{el.id}</span>
                    });
                    setTermsState(terms_view);
                }
            }
        })

    }

    async function setLang(){
        setTermsState(false);
        const key = lang_input.current.value;
        if(key.length < 2){
            error_text.current.innerHTML = "Введіть не меньш 2 символів";
            return;
        }

        const result = await setLanguage(key);
        if(result.error?.data.message === 1000){
            Swal.fire({
                icon: 'error',
                title: 'На жаль, у вас немає необхідних прав',
                confirmButtonText: 'Закрити',
                customClass: {
                    actions: 'modal',
                    cancelButton: 'order-1 right-gap',
                    confirmButton: 'order-2',
                    denyButton: 'order-3',
                }
            });
        }
        if(result.data?.message === 21){
            Toast.fire({icon: 'error', title: `Мова ${result.data.data} вже існує`});
        }
        if(result.data?.message === 22){
            Toast.fire({icon: 'success', title: 'Успіх'});
        }
    }

    async function deleteLang(id){
        setTermsState(false);


        Swal.fire({
            title: 'Видалити мову?',
            text: `Разом із мовою буде видалено усі тексти цієї мови. Видалити?`,
            showCancelButton: true,
            cancelButtonText: 'Скасувати',
            confirmButtonText: 'Видалити',
            icon: 'question',
            customClass: {
              actions: 'modal',
              cancelButton: 'order-1 right-gap',
              confirmButton: 'order-2',
              denyButton: 'order-3',
            }
          }).then(async (result) => {
            if(result.isConfirmed){
                const result = await deleteLanguage(id);
                if(result.data?.message === 24){
                    Toast.fire({icon: 'success', title: 'Успіх'});
                }
            }
        })
    }

    const lang_view = lang.map(ln => {
        return(
            <div key={ln.id} className={s.lang_row}>
                <span className={s.id}>{ln.id}</span>
                <span className={s.key}>{ln.key}</span>
                <span id={ln.id} className={s.active} onClick={(ev)=>activLang(ev.target.id)} style={{backgroundColor: ln.active? '#00A046':'red'}}>{ln.active? 'Активно':'Не активно'}</span>
                <span id={ln.id} className={s.delete_wrapper} onClick={(ev)=>deleteLang(ev.target.id)}>&#10006;</span>
            </div>
        )
    })

    return(
        <div className={s.lang}>
            <h1>Список мов</h1>
            <div className={s.table_lang}>
                <div className={s.lang_row}>
                    <span className={s.id}>ID</span>
                    <span className={s.key}>KEY</span>
                    <span className={s.active}>ACTIVE</span>
                </div>
                {lang_view}
            </div>
            <div className={s.add_lang}>
                <input type="text" placeholder='Введіть KEY, наприклад eng' ref={lang_input}/>
                <div className={`${s.button} button`} onClick={setLang}>Додати</div>
            </div>
            <div className={s.error_text} ref={error_text}></div>
            <div className={s.terms_text}>{termsState? termsState : null}</div>
        </div>
    )
}
export function getDate(timestamp){

    const date = new Date(parseInt(timestamp)); 

    let day = date.getDate();
    let month = date.getMonth() + 1; 
    let year = date.getFullYear() % 100; 
    var hours = date.getHours();
    var minutes = date.getMinutes();


    if (day < 10) {
    day = '0' + day;
    }

    if (month < 10) {
    month = '0' + month;
    }

    if (hours < 10) {
        hours = '0' + hours;
    }
      
    if (minutes < 10) {
    minutes = '0' + minutes;
    }

    return `${day}.${month}.${year} ${hours}:${minutes}`;
}

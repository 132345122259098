import React, { useRef, useState } from 'react';
import { blocksApi, useAddBlockMutation, useAddBlockNewMutation, useGetBlocksTypesQuery, useUpdateBlockMutation, useUpdateBlockNewMutation } from '../../../../redux/apis/blocksApi';
import { useGetLangsQuery } from '../../../../redux/apis/langApi';
import { termsApi, useAddTermsMutation, useCheckTermsPseudoMutation, useGetTermsQuery, useUpdateTermsMutation } from '../../../../redux/apis/termsApi';
import { sectionApi, useAddBlockToSectionMutation, useUpdateSectionMutation } from '../../../../redux/apis/sectionApi';
import { mediaApi, useAddMediaMutation, useGetMediaQuery, useUpdateMediaMutation } from '../../../../redux/apis/mediaApi';
import { useDispatch } from 'react-redux';
import { Toast } from '../../../../utils/hocs/toast';
import Swal from 'sweetalert2';

export const BlocksModal = ({close, section, values, lang_data, type, accept, tm_name, post_body})=>{
    let types_view;
    let groups_view;
    let groups_view_media;
   
    const dispatch = useDispatch();
    const pseudo_ref = useRef();
    const new_group_ref = useRef();
    const img_wrapper = useRef();
    const group_select = useRef();
    const type_select = useRef();
    const error_text = useRef();
    const video = useRef();
    const terms_values = useRef([]);
    const extra_values = useRef([]);

    const [formContent, setFormContent] = useState();
    const [content_type, setType] = useState('text');
    const [inputsValues, setInputsValues] = useState({
        ...values?.terms_block, 
        'link': {value: values?.link? values.link : ''}, 
        'pseudo': {value: values?.pseudo? values.pseudo : ''},
        'alt': {value: values?.file?.alt? values.file.alt : ''}
    });
    
    const [addTerms] = useAddTermsMutation();
    const [addBlock] = useAddBlockMutation();
    const [updateSection] = useUpdateSectionMutation();
    const [updateBlocks] = useUpdateBlockMutation();
    const [updateTerms] = useUpdateTermsMutation();
    const [addMedia] = useAddMediaMutation();
    const [updateMedia] = useUpdateMediaMutation();
    const [addBlockToSection] = useAddBlockToSectionMutation();
    const [addBlockNew] = useAddBlockNewMutation();
    const [updateBlockNew] = useUpdateBlockNewMutation();
    const [checkTerms] = useCheckTermsPseudoMutation();
    
    const {data, isError, isFetching} = useGetBlocksTypesQuery();
    const terms = useGetTermsQuery({});
    const lang = useGetLangsQuery({});
    const media = useGetMediaQuery({});

    

    if(isFetching || terms.isFetching || lang.isFetching || media.isFetching) return <h1>Loading...</h1>
    if(isError) console.log(isError);


    // ### SET CONTENT TYPE

    if(!formContent){
        if(values?.type) changeType(values?.type);
        else if(type) changeType(type);
        else changeType("text");
        return;
    }
    

    // ### VIEWS

    const uniqueArray = terms.data.data.filter((obj, index, self) => {
        return self.findIndex((otherObj) => obj.group === otherObj.group) === index;
        });
    
    groups_view = uniqueArray.map(el => {
        return(
            <option key={el.id} name={el.group} value={el.group}>{el.group}</option> 
        )
    })
    
    const uniqueArrayMedia = media.data.data.filter((obj, index, self) => {
        return self.findIndex((otherObj) => obj.group === otherObj.group) === index;
        });
    groups_view_media = uniqueArrayMedia.map(el => {
        return(
            <option key={el.id} value={el.group}>{el.group}</option> 
        )
    })

    types_view = data.data.map(el => {
        return (
            <option key={el.id} value={el.name}>{el.name}</option>
        )
    })
    

    // ### FUNCTIONS

    function changeType(value){
        switch(value){
            case "text":
            setFormContent(data?.data.find(el => el.name === value).options[0])
            setType('text')
            break;
            
            case "link":
            setFormContent(data?.data.find(el => el.name === value).options[0])
            setType('link')
            break;

            case "button":
            setFormContent(data?.data.find(el => el.name === value).options[0])
            setType('button')
            break;

            case "image":
            setFormContent(data?.data.find(el => el.name === value).options[0])
            setType('image')
            break;

            case "video":
            setFormContent(data?.data.find(el => el.name === value).options[0])
            setType('video')
            break;

            default: Toast.fire({icon: 'success', title: `Не вірний тип.`});
        }
    }

    async function sendForm(ev){
        let err_flag = false;
        error_text.current.innerHTML = "";
        ev.preventDefault();
        
        if(formContent[0].type === 'text' || formContent[0].type === 'link'){
            const pseudo = inputsValues['pseudo'].value;
            const old_group = group_select.current.value;
            const new_group = new_group_ref.current.value;
            const group = new_group.length? new_group : old_group;
            const link = extra_values?.current['link']?.value? extra_values?.current['link']?.value : '';
            
            if(pseudo.length < 2) {
                error_text.current.innerHTML = "Заповніть усі поля";
                err_flag = true;
            }
            lang?.data.data.forEach(el=>{
                if(terms_values.current[el.key].value.length < 2) {
                    error_text.current.innerHTML = "Заповніть усі поля";
                    err_flag = true;
                }
            });

            if(err_flag) return;
            console.log(accept);
            if(accept){
                const result_terms = {}
                result_terms.text = lang.data.data.map((el) => {
                    return {value: terms_values.current[el.key].value, pseudo: pseudo, group: group, lang_id: el.id}
                });
                if(tm_name) result_terms.tm_name = tm_name;
                if(post_body) result_terms.post_body = "post_body";
                accept(result_terms);
                close(false);
                return;
            }

            let terms = [];
            lang?.data.data.map( async el => {
                terms.push({value: terms_values.current[el.key].value, pseudo: pseudo, group: group, lang_id: el.id})
            });
            
            // Edit block
            if(values.type){
                const response = await updateBlockNew({type: content_type, section_id: section.id, link: link, block_id: values.block_id, terms: terms});
                if(response.error?.data.message === 1000){
                    Swal.fire({
                        icon: 'error',
                        title: 'На жаль, у вас немає необхідних прав',
                        confirmButtonText: 'Закрити',
                        customClass: {
                          actions: 'modal',
                          cancelButton: 'order-1 right-gap',
                          confirmButton: 'order-2',
                          denyButton: 'order-3',
                        }
                    })
                    close(false);
                }
                else if(response.data.message === 38){
                    dispatch(termsApi.util.resetApiState())
                    dispatch(blocksApi.util.resetApiState())
                    dispatch(sectionApi.util.resetApiState())
                    Toast.fire({icon: 'success', title: 'Успіх'});
                    close(false)
                }
                return;
            }
            
            const check_terms = await checkTerms([pseudo]);
            console.log(check_terms);
            if(check_terms.data){
                Toast.fire({icon: 'success', title: `Псевдонім ${pseudo} вже зайнятий. Будь ласка, використовуйте унікальні pseudo для тексту.`});
                return;
            }
            
            // Add new block
            const response = await addBlockNew({type: content_type, section_id: section.id, link: link, terms: terms});
            if(response.error?.data.message === 1000){
                Swal.fire({
                    icon: 'error',
                    title: 'На жаль, у вас немає необхідних прав',
                    confirmButtonText: 'Закрити',
                    customClass: {
                      actions: 'modal',
                      cancelButton: 'order-1 right-gap',
                      confirmButton: 'order-2',
                      denyButton: 'order-3',
                    }
                })
                close(false);
            }
            else if(response.data.message === 38){
                dispatch(termsApi.util.resetApiState())
                dispatch(blocksApi.util.resetApiState())
                dispatch(sectionApi.util.resetApiState())
                Toast.fire({icon: 'success', title: 'Успіх'});
                close(false);
                return;
            }
                
        }
        else if(formContent[0].type === 'file'){
            
            let err_flag = false;
            const file = extra_values.current[content_type].files[0];
            const old_group = group_select.current.value;
            const new_group = new_group_ref.current.value;
            const group = new_group.length? new_group : old_group;
            const alt = extra_values.current['alt']?.value;
            const block_id = values?.block_id;

            const form_data = new FormData();
            form_data.append("file", file);
            form_data.append("group", group);
            form_data.append("alt", alt);
            form_data.append("media_id", values?.file.id);
            form_data.append("block_id", block_id);
            form_data.append("file_type", content_type);
            form_data.append("section_id", section?.id);

            if(content_type === 'image'){
                if(group.length < 2 || alt.length < 2) {
                    error_text.current.innerHTML = "Заповніть усі поля";
                    err_flag = true;
                }
            }
            if(content_type === 'video'){
                if(group.length < 2) {
                    error_text.current.innerHTML = "Заповніть усі поля";
                    err_flag = true;
                }
            }

            if(err_flag) return;
            if(accept){
                const result_image = {}
                result_image.image = form_data;
                if(tm_name) result_image.tm_name = tm_name;
                if(post_body) result_image.post_body = "post_body";
                accept(result_image);
                close(false);
                return;
            }

            // Edit block
            if(values.type){
                const response = await updateBlockNew(form_data);
                if(response.error?.data.message === 1000){
                    Swal.fire({
                        icon: 'error',
                        title: 'На жаль, у вас немає необхідних прав',
                        confirmButtonText: 'Закрити',
                        customClass: {
                          actions: 'modal',
                          cancelButton: 'order-1 right-gap',
                          confirmButton: 'order-2',
                          denyButton: 'order-3',
                        }
                    })
                    close(false);
                }
                else if(response.data.message === 38){
                    dispatch(mediaApi.util.resetApiState())
                    dispatch(blocksApi.util.resetApiState())
                    dispatch(sectionApi.util.resetApiState())
                    Toast.fire({icon: 'success', title: 'Успіх'});
                    close(false)
                }
                return;
            }
            
            // New block
            console.log(form_data);
            const response = await addBlockNew(form_data);
            console.log("send");
            if(response.data.message === 38){
                dispatch(mediaApi.util.resetApiState())
                dispatch(blocksApi.util.resetApiState())
                dispatch(sectionApi.util.resetApiState())
                Toast.fire({icon: 'success', title: 'Успіх'});
                close(false);
                return;
            }
            
        }
        
    }

    function previewImage(input){
        if(input.name === "image"){
            const reader = new FileReader();
            reader.onload = function(e) {
            img_wrapper.current.style.cssText= `background: url(${e.target.result}) no-repeat center; background-size: contain;`;
            };
            reader.readAsDataURL(input.files[0]);
        }

        if(input.name === "video"){
            const reader = new FileReader();
            reader.onload = function(e) {
                video.current.src = e.target.result;
                video.current.setAttribute('controls', true);
            };
            reader.readAsDataURL(input.files[0]);
        }
    }

    function changeGroup(ev){
        if(ev.target.value.length){
            group_select.current.disabled = true;
        }else group_select.current.disabled = false;
    }

    function changeInput(el){

        setInputsValues(prev => {
            const newState = JSON.parse(JSON.stringify(prev));
            if(newState[el.getAttribute('refval')]) newState[el.getAttribute('refval')].value = el.value;
            return newState;
        });
    }
 
    return(
        <div className="modal_wrapper">
            <div className="modal">
                <h2>{values?.type? "Змінити Блок" : "Додати Блок"}</h2>
                <form className="modal_form" >
                    <div className="form_row">
                        <label>Тип</label>
                        <select value={content_type} disabled={values?.type || type? true:false}  type="select" onChange={(ev)=>changeType(ev.target.value)} ref={type_select}>
                            {types_view}
                        </select>
                    </div>
                    {
                    formContent.map((el, index) => {
                        if(el.label === "Текст"){
                            return(
                                lang?.data?.data.map((ln)=>{
                                    
                                    return(
                                        <div key={ln.id} className="form_row">
                                            <label>{`${el.label}-${ln.key}`}</label>
                                            <textarea type={el.type} name={`${el.label}-${ln.key}`} refval={ln.id} value={inputsValues[ln.id]?.value} ref={ref=>terms_values.current[ln.key] = ref} onChange={(ev)=>changeInput(ev.target)} rows="10"></textarea>
                                        </div>
                                    )
                                })
                            )     
                        }else{
                            return(
                                <div key={index} className="form_row">
                                    <label>{el.label}</label>
                                    {el.name === 'alt'?
                                        <input type={el.type}  name={el.name} value={inputsValues['alt'].value} onChange={(ev)=> changeInput(ev.target)} ref={ref=>extra_values.current[el.name] = ref} refval={'alt'}/>
                                        :<input type={el.type} accept={el.name === 'image'? 'image/*' : 'video/*'} value={el.name === 'link'? inputsValues['link'].value : null} name={el.name} onChange={(ev)=>el.type === "file"? previewImage(ev.target) : changeInput(ev.target)} ref={ref=>extra_values.current[el.name] = ref} refval={el.type === 'file'? 'file' : 'link'}/>
                                    }
                                    {el.name === "image"? <div className='img_wrapper' style={{background: `url(${values?.file.link}) no-repeat center/contain`}} ref={img_wrapper}></div> : null}
                                    {el.name === "video"? <video playsInline ref={video} src={values.file.link} controls={values.file.path? true:false} style={{width: "100%", height: "100%"}} />: null}
                                </div>
                            )
                        }
                    })
                    }
                   
                        
                    <div className='form_group'>
                        <div className="form_row_group">
                            <div className="left">
                                <label>Вибрати групу</label>
                                <select ref={group_select} name="group" defaultValue={values?.type? values.type === 'text'? values.terms_block[values.terms_block.length - 1].group: values.file.group : false}>
                                    {content_type === 'image' || content_type === 'file'? groups_view_media : groups_view}
                                </select>
                            </div>
                            <div className="right">
                                <label>Додати нову</label>
                                <input type="text" ref={new_group_ref} onChange={(ev)=>{changeGroup(ev)}}/>
                            </div>
                        </div>
                        {content_type === "text" || content_type === "link"? (
                            <div className="form_row">
                            <label>Псевдонім</label>
                            <input onChange={(ev)=>changeInput(ev.target)} refval='pseudo' type="text" name="pseudo" value={inputsValues['pseudo'].value} ref={pseudo_ref} disabled={values?.type? true:false}/>
                        </div>
                        ) : null}
                    </div>
                    
                    
                     <span className="error_text" ref={error_text}></span>
                    <input className="button" type="button" value="Відправити" onClick={(ev)=>sendForm(ev)} />
                    <input className="button" type="submit" value="Скасувати" onClick={()=>close(false)}/>
                </form>
            </div>
        </div>
    )
}
import React from 'react';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit_icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete_icon.svg';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDeleteUserMutation, useGetUsersQuery } from '../../../redux/apis/userApi';
import { setIsLoading } from '../../../redux/slices/mainSlice';
import { Registration } from '../../user/registration/registration';
import s from './users.module.scss';
import { Toast } from '../../../utils/hocs/toast';

export const Users = ()=>{

    const Swal = require('sweetalert2');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [regState, setRegState] = useState(false);
    const [confirmState, setConfirmState] = useState(false);

    const [delUser] = useDeleteUserMutation();

    const users_query = useGetUsersQuery();

    if(users_query.isFetching) dispatch(setIsLoading(true));
    else dispatch(setIsLoading(false));

    if(users_query.error){
        if(!confirmState && users_query.error.data.message === 9){
            Swal.fire({
                icon: 'error',
                text: `У вас не вистачає прав для цієї операції.`,
                confirmButtonColor: "#EC722E"
            }).then((result)=>{
                if(result.isConfirmed) navigate('/')
            })
            return;
        }
    }
    if(!users_query.data) return;
    
    async function deleteUser(el){
        Swal.fire({
            title: 'Видалити юзера?',
            showCancelButton: true,
            confirmButtonText: 'Видалити',
            customClass: {
              actions: 'modal',
              cancelButton: 'order-1 right-gap',
              confirmButton: 'order-2',
              denyButton: 'order-3',
            }
          }).then(async (result) => {
            if(result.isConfirmed){
                const id = el.getAttribute('id');
                const result = await delUser(id);
                
                if(result.data.message === 7 ){
                    Toast.fire({icon: 'success', title: 'Успіх'});
                }
            }
        })
    }

    const users = users_query.data;
    
    const users_view = users.map(user => {
        return (
            <tr key={user.id}>
                <td className={s.user_id}><span>{user.id}</span></td>
                <td>{user.info.name}</td>
                <td>{user.email}
                    <span className={s.tools_icon} id={user.id} onClick={(ev)=>deleteUser(ev.target)}>
                        <DeleteIcon/>
                    </span>
                    <span className={s.tools_icon} onClick={()=>setRegState({user: user})}>
                        <EditIcon/>
                    </span>
                </td>
            </tr>
        )
    })

    return(
        <div className={s.users}>
            <h1>Юзери</h1>
            <div className={s.content}>
                <table>
                    <thead>
                        <tr>
                            <td><span>id</span></td>
                            <td><span>ім'я</span></td>
                            <td><span>email</span></td>
                        </tr>
                    </thead>
                    <tbody>
                    {users_view}
                    </tbody>
                </table>
                <div className="button" onClick={()=>setRegState(true)}>Додати юзера</div>
            </div>
            {regState? <Registration close={setRegState} user={regState.user}/> : null}
        </div>
        
    )
}
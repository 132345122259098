import React from 'react';
import s from './preloader.module.scss';

export const Preloader = () =>{

    return(
        <div className={s.preloader}>
            <h1>Loading...</h1>
        </div>
    )
}
import { Formik, Form, Field } from 'formik';
import s from './auth.module.scss';
import * as yup from 'yup';
import { useAuthMutation } from '../../../redux/apis/userApi';
import { useDispatch } from 'react-redux';
import { setIsLoading } from '../../../redux/slices/mainSlice';
import { useNavigate } from 'react-router-dom';
import { useCrypto } from '../../../utils/hocs/crypto';

export const Auth = ()=>{

    const dispatch = useDispatch();
    const navigate = useNavigate();
    let response = false;

    const [auth, {data, isLoading, isError}] = useAuthMutation();
    if(isError) console.log(isError);
    if(isLoading) dispatch(setIsLoading(true));
    else dispatch(setIsLoading(false));
    if(data){
        if(data.message === 8) response = "Не правильный email или пароль";
        if(data.message === 10){
            localStorage.setItem("token", data.data.token);
            localStorage.setItem("userData", JSON.stringify(data.data.userData));
            navigate("/");
        }
    }

    const initialValues = { email: '', password: ''}
    const validationSchema = yup.object().shape({
        password: yup.string().required('Обязательное поле'),
        email: yup.string().required('Обязательное поле').email('Не корректный email') 
    });

    function LogIn(values){
        const email = values.email;
        const password = useCrypto(values.password);
        auth({email: email, password: password});
    }

    return(
        <div className={s.auth_wrapper}>
            <div className={s.auth}>
                <h1>Вхід</h1>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={(values)=> LogIn(values)}
                    >
                    {({errors, touched}) => (
                        <Form className={s.auth_form}>
                        <div className={s.form_row}>
                            <label>
                                Email
                            </label>
                            <Field name="email" type="text"/>
                            <span className={s.error_text}>{touched.email? errors.email : null}</span>
                        </div>

                        <div className={s.form_row}>
                            <label>
                                Password
                            </label>
                            <Field name="password" type="password"/>
                            <span className={s.error_text}>{touched.password? errors.password : null}</span>
                        </div>

                        <button type="submit">Увійти</button>
                        <span className={s.error_text}>{response}</span>
                    </Form>
                    )}
                </Formik>
                {/* <p className={s.notes}><Link to="/registration/">Зареєструватись</Link></p> */}
            </div>
        </div>
    )
}
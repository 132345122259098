import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const api = process.env.REACT_APP_API_ADDRESS;

export const userApi = createApi({
    reducerPath: 'userApi',
    tagTypes: ['Users'],
    baseQuery: fetchBaseQuery({
      baseUrl: api,
      prepareHeaders: (headers) => {
        const token = localStorage.getItem("token");
        if(token) headers.set("token", token);
        return headers;
      }
    }),
    endpoints: (builder) => ({


      checkToken: builder.query({
        query: () => `/api/user/check`,
      }),

      getUsers: builder.query({
        query: () => `/api/user/get/users`,
        providesTags: (result = false) =>
        result.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'Users', id })),
              { type: 'Users', id: 'LIST' },
            ]
          : [{ type: 'Users', id: 'LIST' }],
      }),
      
      auth: builder.mutation({
        query: (data) => ({
          url: '/api/user/auth',
          method: 'POST',
          body: { email: data.email, password: data.password},
        }),
      }),

      registration: builder.mutation({
        query: (data) => ({
          url: '/api/user/registration',
          method: 'POST',
          body: { email: data.email, password: data.password},
        }),
      }),

      registrationConfirm: builder.mutation({
        query: (data) => ({
          url: '/api/user/registration/confirm',
          method: 'POST',
          body: { verify_code: data.code},
        }),
      }),

      saveDatabase: builder.mutation({
        query: () => ({
          url: '/api/db/save/',
          method: 'POST',
          body: {},
        }),
      }),

      addUser: builder.mutation({
        query: (data) => ({
          url: '/api/user/add',
          method: 'POST',
          body: { email: data.email, name: data.name, password: data.password, permissions: data.permissions},
        }),
        invalidatesTags: [{ type: 'Users', id: 'LIST' }],
      }),

      deleteUser: builder.mutation({
        query: (data) => ({
          url: '/api/user/delete',
          method: 'DELETE',
          body: {user_id: data},
        }),
        invalidatesTags: [{ type: 'Users', id: 'LIST' }],
      }),

      updateUser: builder.mutation({
        query: (data) => ({
          url: '/api/user/update',
          method: 'PUT',
          body: {user_id: data.user_id, email: data.email, name: {name: data.name}, permissions: data.permissions},
        }),
        invalidatesTags: [{ type: 'Users', id: 'LIST' }],
      }),

      getPermissions: builder.query({
        query: () => `/api/user/permissions/get`,
      }),

    }),
  })

export const {
  useCheckTokenQuery, 
  useAuthMutation,
  useRegistrationMutation,
  useRegistrationConfirmMutation,
  useSaveDatabaseMutation,
  useGetUsersQuery,
  useAddUserMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useGetPermissionsQuery
} = userApi;
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const api = process.env.REACT_APP_API_ADDRESS;

export const termsApi = createApi({
    reducerPath: 'termsApi',
    tagTypes: ['Terms'],
    baseQuery: fetchBaseQuery({
      baseUrl: api,
      prepareHeaders: (headers) => {
        const token = localStorage.getItem("token");
        if(token) headers.set("token", token);
        return headers;
      }
    }),
    endpoints: (builder) => ({

      getTerms: builder.query({
        query: ({id, value, pseudo, group, created_date, author, lang_id, limit, offset}) => 
`/api/terms/get?id=${id? JSON.stringify(id):false}&value=${value? JSON.stringify(value):''}&pseudo=${pseudo? JSON.stringify(pseudo):''}&group=${group? JSON.stringify(group):''}&created_date=${created_date? JSON.stringify(created_date):''}&author=${author? JSON.stringify(author):''}&lang_id=${lang_id? JSON.stringify(lang_id):''}&limit=${limit? JSON.stringify(limit):''}&offset=${offset? JSON.stringify(offset):''}`,
        providesTags: (result) =>
        result.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'Terms', id })),
              { type: 'Terms', id: 'LIST' },
            ]
          : [{ type: 'Terms', id: 'LIST' }],
      }),

      addTerms: builder.mutation({
        query: (data) => ({
          url: '/api/terms/set',
          method: 'POST',
          body: { id: data.id, value: data.value, pseudo: data.pseudo, group: data.group, lang_id: data.lang_id},
        }),
        // invalidatesTags: [{ type: 'Terms', id: 'LIST' }],
      }),

      updateTerms: builder.mutation({
        query: (data) => ({
          url: '/api/terms/update',
          method: 'PUT',
          body: {id: data.id, value: data.value, pseudo: data.pseudo, group: data.group, lang_id: data.lang_id},
        }),
        invalidatesTags: [{ type: 'Terms', id: 'LIST' }],
      }),

      deleteTerms: builder.mutation({
        query: (id) => ({
          url: '/api/terms/delete',
          method: 'DELETE',
          body: { id: id},
        }),
        invalidatesTags: [{ type: 'Terms', id: 'LIST' }],
      }),

      deleteTermsPseudo: builder.mutation({
        query: (pseudo) => ({
          url: '/api/terms/delete/pseudo',
          method: 'DELETE',
          body: { pseudo: pseudo},
        }),
        invalidatesTags: [{ type: 'Terms', id: 'LIST' }],
      }),

      checkTermsPseudo: builder.mutation({
        query: (pseudo) => ({
          url: '/api/terms/check/pseudo',
          method: 'POST',
          body: { pseudo: pseudo},
        }),
      }),
      

      }),
})

export const {
  useGetTermsQuery,
  useAddTermsMutation,
  useUpdateTermsMutation,
  useDeleteTermsMutation,
  useDeleteTermsPseudoMutation,
  useCheckTermsPseudoMutation
} = termsApi;
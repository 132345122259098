import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Arrows } from '../../../components/arrows/arrows';
import { EditModal } from '../../../components/editModal/editModal';
import { Preloader } from '../../../components/preloader/preloader';
import { useGetLangsQuery } from '../../../redux/apis/langApi';
import { useGetMediaQuery } from '../../../redux/apis/mediaApi';
import { useDeletePostContentMutation, useGetGroupsTemplateQuery, useGetPostsContentQuery, useGetPostsGroupsQuery, useGetPostsQuery } from '../../../redux/apis/postsApi';
import { useGetTermsQuery } from '../../../redux/apis/termsApi';
import { key } from '../../../utils/getKey';
import { confirm } from '../../../utils/hocs/confirm';
import { Toast } from '../../../utils/hocs/toast';
import s from './posts.module.scss';

export const Posts = ()=>{
    
    const [groupState, setGroupState] = useState(false);
    const [langState, setLangState] = useState(false);
    const [editModal, setEditModal] = useState(false);

    const post_groups_query = useGetPostsGroupsQuery({});
    const posts_query = useGetPostsQuery({group: groupState});
    const templates_query = useGetGroupsTemplateQuery({});
    const terms_query = useGetTermsQuery({});
    const media_query = useGetMediaQuery({});
    const lang_query = useGetLangsQuery({});
    const posts_content_query = useGetPostsContentQuery({});

    const [deletePostContent] = useDeletePostContentMutation();

    if(post_groups_query.isFetching || posts_query.isFetching || templates_query.isFetching || terms_query.isFetching || media_query.isFetching || lang_query.isFetching || posts_content_query.isFetching) return <Preloader/>
    if(post_groups_query.isError || posts_query.isError || templates_query.isError || terms_query.isError || media_query.isError || lang_query.isError || posts_content_query.isError) console.log(post_groups_query.isError ,posts_query.isError, templates_query.isError, terms_query.isError, media_query.isError, lang_query.isError, posts_content_query.isError);

    const groups = post_groups_query.data.data;
    const posts = posts_query.data.data;
    const terms = terms_query.data.data;
    const media = media_query.data.data;
    const lang = lang_query.data.data;
    const posts_content = posts_content_query.data.data;

    if(!groupState || !langState){
        if(groups.length){
            setGroupState(groups[0].id);
            setLangState(lang[0].id);
            return;
        }
        return;
    }

    // ### FUNCTIONS

    async function deleteElement(type, id, pseudo, post_id, content){
       
        confirm("Видалити елемент?").then(async (res)=>{
            if(res.isConfirmed){
                const result = await deletePostContent({type: type, media_id: id, value: pseudo, post_id: post_id, content: content})
                if(result.data?.message === 50 || result.data?.message === 56){
                    Toast.fire({icon: 'success', title: 'Успіх'});
                }
                else if(result.error?.data.message === 1000){
                    Swal.fire({
                        title: 'На жаль, у вас немає необхідних прав',
                        confirmButtonText: 'Закрити',
                        customClass: {
                          actions: 'modal',
                          cancelButton: 'order-1 right-gap',
                          confirmButton: 'order-2',
                          denyButton: 'order-3',
                        }
                      })
                }
            }
        })

    }

    // ### VIEWS
    
    const groups_view = groups.map(el => {
        return(
            <div key={el.id} className={s.group_item} onClick={() => setGroupState(el.id)}>{el.name}</div>
        )
    });

    const posts_view = posts.map(post => {
     
        const content = posts_content.filter(el => el.post_id === post.id);
        
        return(
            <div key={post.id} className={s.post}>
                    {
                        post.template.map(tm => {
                            if(tm.tm_type === 'text'){
                                const terms_data = terms.filter(el => el.pseudo === tm.value && el.lang_id === langState);
                                return(
                                    <div key={key()} className={s.template_wrapper}>
                                        <div className={s.lang_block}>
                                            <div className={s.title}>{tm.tm_title}</div>
                                            {
                                            lang.map(el => {
                                                return (
                                                    <div key={el.id} className={s.lang_item} onClick={()=>setLangState(el.id)}>
                                                        <div className={s.lang}>{el.key}</div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                        {
                                          terms_data.map(el => {
                                            return(
                                                <div key={el.id} className={s.text_block}>
                                                    <div key={key()} className={s.text_wrapper}>
                                                    <div className={s.lang_value}>{el.value}</div>
                                                        <div className={s.tools_block}><span onClick={()=>setEditModal({type: tm.tm_type, id: el.id, pseudo: el.pseudo})}>&#9998;</span></div>
                                                    </div>
                                                </div>
                                            )
                                          }) 
                                        }
                                    </div>
                                )
                            }
                    
                            if(tm.tm_type === 'file'){
                                if(tm.tm_name === 'news_title_image'){
                                    const image = media.find(el => el.id == tm.value);
                                    return(
                                        <div key={key()} className={s.template_wrapper_image}>
                                            <div className={s.title}>{tm.tm_title}</div>
                                            <div className={s.img_wrapper} style={{
                                                background: `url(${image.link}) no-repeat center/contain`
                                            }}>
                                                <div key={key()} className={`${s.block_wrapper} ${s.img_wrapper}`}>
                                                    <div className={s.tools_block}></div><div className={s.tools_block}><span onClick={()=>setEditModal({type: tm.tm_type, id: image.id, pseudo: image.pseudo})}>&#9998;</span></div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            }
                        })
                    }
                    <div className={s.body}>

                        <div className={s.body_title}>Тіло поста</div>
                        <div className={s.lang_block}>
                            {
                            lang.map(el => {
                                return (
                                    <div key={el.id} className={s.lang_item} onClick={()=>setLangState(el.id)}>
                                        <div className={s.lang}>{el.key}</div>
                                    </div>
                                )
                            })
                            }
                        </div>

                        {   
                            content.map(el => {
                                if(el.type === "block"){
                                    const image = media.find(media => media.id == el.content.media);
                                    return(
                                        <div key={key()} className={s.block_wrapper} >
                                            <div className={s.img_wrapper} style={{float: `${el.style}`}}>
                                                <img src={image.link} alt={image.alt} />
                                                <div className={s.tools_block}></div><div className={s.tools_block}><span onClick={()=>setEditModal({type: 'image', id: image.id, pseudo: image.pseudo})}>&#9998;</span><span onClick={(ev)=>deleteElement('block', image.id, image.pseudo, post.id, el)}>&#10006;</span></div>
                                            </div>
                                                {
                                                    el.content.text.map(text => {
                                                        const tm = terms.find(terms => terms.pseudo === text && terms.lang_id === langState);
                                                        return(
                                                            <div key={key()} className={s.text_wrapper}>
                                                                <p key={key()}>{terms.find(terms => terms.pseudo === text && terms.lang_id === langState).value}</p>
                                                                <div className={s.tools_block}><span onClick={()=>setEditModal({type: 'text', id: tm.id, pseudo: tm.pseudo})}>&#9998;</span><span onClick={(ev)=>deleteElement('block', tm.id, tm.pseudo, post.id, el)}>&#10006;</span></div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                        </div>
                                    )
                                }
                                if(el.type === "image"){
                                    const image = media.find(media => media.id === el.value);
                                    return(
                                        <div key={key()} className={`${s.block_wrapper} ${s.img_wrapper}`}>
                                            <img src={image.link} alt={image.alt} />
                                            <div className={s.tools_block}></div><div className={s.tools_block}><span onClick={()=>setEditModal({type: el.type, id: image.id, pseudo: image.pseudo})}>&#9998;</span><span onClick={(ev)=>deleteElement('image', image.id, image.pseudo, post.id, el)}>&#10006;</span></div>
                                        </div>
                                    )
                                }
                                if(el.type === "text"){
                                    const text = terms.filter(terms => terms.pseudo === el.value && terms.lang_id === langState)
                                    return(
                                        <div key={key()} className={s.text_wrapper}>
                                            <span>{text[0].value}</span>
                                            <div className={s.tools_block}><span onClick={()=>setEditModal({type: el.type, id: text[0].id, pseudo: text[0].pseudo})}>&#9998;</span><span onClick={(ev)=>deleteElement('text', text[0].id, text[0].pseudo, post.id, el)}>&#10006;</span></div>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
            </div>
        )
    })

    return(
        <div className={s.posts}>
            <header>
                {groups_view}
            </header>
            <Link to={`/addpost?group=${groupState}`} className={s.add_post}>Додати пост<span className={s.add_icon}></span></Link>
            <div className={s.content}>
                {posts_view}
            </div>
            <Arrows/>
            {editModal? <EditModal type={editModal.type} id={editModal.id} pseudo={editModal.pseudo} close={setEditModal}/> : null}
        </div>
    )
}
import { createSlice } from "@reduxjs/toolkit/";

 const defaultState = {
    isLogged: false,
    isLoading: true,
    confirm: false,
 };

  const mainSlice = createSlice({
    name: "main",
    initialState: defaultState,
    reducers: {
       setIslogged(store, action){
          store.isLogged = action.payload;
       },
       setIsLoading(store, action){
         store.isLoading = action.payload;
       },
       setConfirm(store, action){
         store.confirm = action.payload;
       }
    },
  });

export const { 
   setIslogged,
   setIsLoading,
   setConfirm
} = mainSlice.actions;
export default mainSlice.reducer;
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const api = process.env.REACT_APP_API_ADDRESS;

export const postsApi = createApi({
    reducerPath: 'postsApi',
    tagTypes: ['Posts'],
    baseQuery: fetchBaseQuery({
      baseUrl: api,
      prepareHeaders: (headers) => {
        const token = localStorage.getItem("token");
        if(token) headers.set("token", token);
        return headers;
      }
    }),
    endpoints: (builder) => ({

      getPosts: builder.query({
        query: (data) =>  `/api/posts/get?group=${data.group? JSON.stringify([data.group]) : ''}`,
        providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'Posts', id })),
              { type: 'Posts', id: 'LIST' },
            ]
          : [{ type: 'Posts', id: 'LIST' }],
      }),

      addPost: builder.mutation({
        query: (data) => ({
          url: '/api/posts/set',
          method: 'POST',
          body: data
        })
      }),

      addPostTerms: builder.mutation({
        query: (data) => ({
          url: '/api/posts/set/terms',
          method: 'POST',
          body: data,
          
        })
      }),

      addPostMedia: builder.mutation({
        query: (data) => ({
          url: '/api/posts/set/media',
          method: 'POST',
          body: data,
          
        })
      }),

      updatePostTerms: builder.mutation({
        query: (data) => ({
          url: '/api/posts/update/terms',
          method: 'PUT',
          body: data,
          
        })
      }),

      updatePostMedia: builder.mutation({
        query: (data) => ({
          url: '/api/posts/update/media',
          method: 'PUT',
          body: data,
          
        })
      }),


      updatePosts: builder.mutation({
        query: (data) => ({
          url: '/api/posts/update',
          method: 'PUT',
          body: {id: data.id, name: data.name, href: data.href, content: data.content},
        }),
        invalidatesTags: [{ type: 'Posts', id: 'LIST' }],
      }),

      deletePosts: builder.mutation({
        query: (id) => ({
          url: '/api/posts/delete',
          method: 'DELETE',
          body: {id: id},
        }),
        invalidatesTags: [{ type: 'Posts', id: 'LIST' }],
      }),
      
      getPostsGroups: builder.query({
        query: (data) =>  `/api/posts/groups/get?id=${data.id? JSON.stringify([data.id]) : null}`,
        providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'Posts', id })),
              { type: 'Posts', id: 'LIST' },
            ]
          : [{ type: 'Posts', id: 'LIST' }],
      }),

      getGroupsTemplate: builder.query({
        query: (data) =>  `/api/posts/template/get?id=${data.id? JSON.stringify(data.id) : null}`,
        providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'Posts', id })),
              { type: 'Posts', id: 'LIST' },
            ]
          : [{ type: 'Posts', id: 'LIST' }],
      }),

      getPostsContent: builder.query({
        query: () =>  `/api/posts/content/get`,
        providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'Posts', id })),
              { type: 'Posts', id: 'LIST' },
            ]
          : [{ type: 'Posts', id: 'LIST' }],
      }),

      addPostContent: builder.mutation({
        query: (data) => ({
          url: '/api/posts/content/set',
          method: 'POST',
          body: data,
        }), 
        invalidatesTags: [{ type: 'Posts', id: 'LIST' }],
      }),

      deletePostContent: builder.mutation({
        query: (data) => ({
          url: '/api/posts/content/delete',
          method: 'DELETE',
          body: data,
        }), 
        invalidatesTags: [{ type: 'Posts', id: 'LIST' }],
      }),
    }),
})

export const {
    useGetPostsQuery,
    useAddPostMutation,
    useUpdatePostsMutation,
    useDeletePostsMutation,
    useGetPostsGroupsQuery,
    useGetGroupsTemplateQuery,
    useGetPostsContentQuery,
    useAddPostContentMutation,
    useDeletePostContentMutation,
    useAddPostTermsMutation,
    useAddPostMediaMutation,
    useUpdatePostTermsMutation,
    useUpdatePostMediaMutation
} = postsApi;